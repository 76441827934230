import React,{useState, useEffect, useContext} from 'react';
import axios from 'axios';
import { useParams, Link } from 'react-router-dom';


const UserAPI = (token) => {
    const [isLogged, setIsLogged] = useState(false);
    const [isAdmin, setIsAdmin] = useState(false);
    const [cart, setCart] = useState([]);
    const [user, setUser] = useState([]);

    useEffect(() => {
        if(token) {
            const getUser = async () => {
                try {
                    const res = await axios.get('/user/infor', {
                        headers: {Authorization: token}
                       
                    });

                    setUser(res.data)
                    setIsLogged(true);
                    res.data.role === 1 ? setIsAdmin(true) : setIsAdmin(false)

                    //като refresh да не ни изчезват продуктите от количката
                    setCart(res.data.cart);
                    
                } catch (err) {
                    //alert(err.response.data.msg);
                }
            }

            getUser()
        }
    }, [token]);


    const addCart = async (product, withoutRegistration, color, size, setColor, setSize) => {
       

        if(withoutRegistration) {
            const check = cart.every(item => {
                return item._id !== product._id;
            });
            if(check) {
                if(!color){
                    return alert("Моля Добавете цвят");
                }

                if(!size){
                    return alert("Моля Добавете размер!");
                }
                setCart([...cart, {...product, quantity: 1, color : color, size_choice: size}]);
                window.localStorage.setItem('products', JSON.stringify([...cart, {...product, quantity: 1, color : color, size_choice: size}]));
            }else{
                //alert("Този продукт вече е  добавен в количката!")
                if(!color){
                    return alert("Моля Добавете цвят");
                }

                if(!size){
                    return alert("Моля Добавете размер!");
                }
                setCart([...cart, {...product, quantity: 1, color : color, size_choice: size}]);
            }
            setColor("") 
            setSize("")
            //return alert("Успешно добавихте продукт към количката!");

        }else{
            if(!isLogged) {
                return alert("Моля влезте в профила си, за да добавите продукт в количката или Влезте като гост!");
                
           }
   
        //    const check = cart.every(item => {
        //        return item._id !== product._id;
        //    });
           const check = true;
   
           if(check) {
            if(!color){
                return alert("Моля Добавете цвят!");
            }

            if(!size){
                return alert("Моля Добавете размер!");
            }
               setCart([...cart, {...product, quantity: 1, color : color, size_choice: size}]);
               await axios.patch('/user/addcart', {cart: [...cart, {...product, quantity: 1, color : color, size_choice: size}]}, {
                   headers: {Authorization: token}
               })
               return alert("Успешно добавихте продукт към количката!");
           }else{
               alert("Този продукт вече е  добавен в количката!")
           }
        }

    }

    return {
        isLogged: [isLogged, setIsLogged],
        isAdmin:  [isAdmin, setIsAdmin],
        cart: [cart, setCart],
        addCart: addCart,
        user: [user]
    }
}

export default UserAPI
