import emailjs from "emailjs-com";
import React from 'react';
import toastr from 'toastr';
import Iframe from 'react-iframe'
import MetaTags from 'react-meta-tags';

const Contact = () => {

    function sendEmail(e) {
        e.preventDefault();

        emailjs.sendForm(
            "service_k46htce",
            'template_63evh4b',
            e.target,
            'user_3ke5d9TV80hRpR6NmI9dY')
            .then((result) => {
                console.log(result.text);
            }, (error) => {
                console.log(error.text);
            });
        e.target.reset()

        toastr.success('Вашето съобщение е изпратено успешно');
    }

    return (
        <section className="contact">
            <MetaTags>
                <title>Контакти</title>
                <meta id="meta-description" name="description" content="контакти, имейл адрес и телефон на princessbutik.bg" />
            </MetaTags>
            <div className="content">
                <h1>Контакти Princess Butik</h1>
            </div>
            <Iframe className="iframe" src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2930.3707787827207!2d23.315448315468277!3d42.73820697916327!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x40aa9027d5e6c5e3%3A0x2699abd82d77abe8!2z0KHRgtC-0LrQvtCyINCR0LDQt9Cw0YAg0JjQu9C40Y_QvdGG0Lg!5e0!3m2!1sbg!2sbg!4v1632489901786!5m2!1sbg!2sbg" width="1500" height="300" style="border:0;" allowfullscreen="" loading="lazy"></Iframe>
            <div className="container">
                <div className="contactInfo">
                    <div className="box">
                        <div className="icon"><i className="fa fa-map-marker" aria-hidden="true"></i></div>
                        <div className="text">
                            <h3>Адрес</h3>
                            <p>Офис-Магазин,<br />гр.София,<br />Стоков базар Илиянци- МОЛ ,маг. 110</p>
                        </div>
                    </div>
                    <div className="box">
                        <div className="icon"><i className="fa fa-phone" aria-hidden="true"></i></div>
                        <div className="text">
                            <h3>Контакти</h3>
                            <p>+359 898 764 573</p>
                        </div>
                    </div>
                    <div className="box">
                        <div className="icon"><i className="fas fa-envelope" aria-hidden="true"></i></div>
                        <div className="text">
                            <h3>Email</h3>
                            <p>princessbutik@abv.bg</p>
                        </div>
                    </div>
                </div>
                <div className="contactForm">
                    <form onSubmit={sendEmail}>
                        <h2>Оставете ни Съобщение</h2>
                        <div className="inputBox">
                            <input name="name" type="text" required="required" />
                            <span>Име</span>
                        </div>
                        <div className="inputBox">
                            <input name="email" type="email" required="required" />
                            <span>E-mail</span>
                        </div>
                        <div className="inputBox">
                            <input name="telephone_number" type="text" required="required" />
                            <span>Телефон</span>
                        </div>
                        <div className="inputBox">
                            <textarea name="message" id="" required="required"></textarea>
                            <span>Вашето съобщение...</span>
                        </div>
                        <div className="inputBox">
                            <input type="submit" value="Изпрати" />
                        </div>
                    </form>
                </div>
            </div>
        </section>


    )
}

export default Contact;