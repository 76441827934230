import React, {  useContext, useState } from 'react'
import { GlobalState } from '../../../GlobalState';
import MetaTags from 'react-meta-tags';

import ProductItem from '../utils/ProductItem/ProductItem';
import Loading from '../utils/loading/Loading';
import axios from 'axios';
import Filteres from './Filters';
import LoadMore from './LoadMore';

const Products = () => {
    const state = useContext(GlobalState);
    const [products, setProducts] = state.productsAPI.products;
    const [isAdmin] = state.userAPI.isAdmin;
    const [token] = state.token;
    const [callback, setCallback] = state.productsAPI.callback;
    const [loading, setLoading] = useState(false);
    const [isCheck, setIsCheck] = useState(false);
    
    const handleCheck = (id) => {
       products.forEach(product => {
           if(product._id === id){
               product.checked = !product.checked
           }
       })
       setProducts([...products]);
    }

    const deleteProduct = async(id, public_id, public_id_two, public_id_three, public_id_four, public_id_five, public_id_six, public_id_seven, public_id_eight, public_id_nine, public_id_ten, public_id_eleven, public_id_twelve) => {
   
        try {
          setLoading(true);
          const destroyImg = axios.post('/api/destroy', { public_id }, {
              headers: { Authorization: token }
          })
          const destroyImgTwo = axios.post('/api/destroy', { public_id_two }, {
              headers: { Authorization: token }
          })
          const destroyImgThree = axios.post('/api/destroy', { public_id_three }, {
              headers: { Authorization: token }
          })

          const destroyImgFour = axios.post('/api/destroy', { public_id_four }, {
            headers: { Authorization: token }
        })

        const destroyImgFive = axios.post('/api/destroy', { public_id_five }, {
            headers: { Authorization: token }
        })

        const destroyImgSix = axios.post('/api/destroy', { public_id_six }, {
            headers: { Authorization: token }
        })

        const destroyImgSeven = axios.post('/api/destroy', { public_id_seven }, {
            headers: { Authorization: token }
        })

        const destroyImgEight = axios.post('/api/destroy', { public_id_eight }, {
            headers: { Authorization: token }
        })

        const destroyImgNine = axios.post('/api/destroy', { public_id_nine }, {
            headers: { Authorization: token }
        })

        const destroyImgTen = axios.post('/api/destroy', { public_id_ten }, {
            headers: { Authorization: token }
        })

        const destroyImgEleven = axios.post('/api/destroy', { public_id_eleven }, {
            headers: { Authorization: token }
        })

        const destroyImgTwelve = axios.post('/api/destroy', { public_id_twelve }, {
            headers: { Authorization: token }
        })

          const deleteProduct = axios.delete(`/api/products/${id}`, {
              headers: { Authorization: token }
          })
         
          await destroyImg;
           await destroyImgTwo;
          await destroyImgThree;
          await destroyImgFour;
          await destroyImgFive;
          await destroyImgSix;
          await destroyImgSeven;
          await destroyImgEight;
          await destroyImgNine;
          await destroyImgTen;
          await destroyImgEleven;
          await destroyImgTwelve;
          await deleteProduct;
          setCallback(!callback);
          setLoading(false);
        } catch (err) {
            alert(err.response.data.msg)
        }
    }

    const checkAll = () => {
        products.forEach(product => {
            product.checked = !isCheck
        })
        setProducts([...products])
        setIsCheck(!isCheck)
    }

    const deleteAll = () =>{
        products.forEach(product => {
            if(product.checked) deleteProduct(
                product._id, product.images.public_id, product.imagesTwo.public_id, 
                product.imagesThree.public_id, product.imagesFour.public_id, 
                product.imagesFive.public_id, product.imagesSix.public_id, 
                product.imagesSeven.public_id, product.imagesEight.public_id, product.imagesNine.public_id, product.imagesTen.public_id, product.imagesEleven.public_id, product.imagesTwelve.public_id)
        })
    }

    if(loading) return <div><Loading /></div>
    return (
        <>
         <MetaTags>
            <title>Продукти за дамска мода | Princess Butik</title>
            <meta id="meta-description" name="description" content="Разгледайте и изберете дамски дрехи за ежедневието и бъдете в крак с тенденциите." />
          </MetaTags>
          <h1 className="heading_product">Дамски дрехи, всеки сезон нова мода на ниски цени</h1>
        <Filteres />
        {
            isAdmin && 
            <div className="delete-all">
                <span>Select All</span>
                <input type="checkbox"  checked={isCheck} onChange={checkAll} />
                <button onClick={deleteAll}>Delete ALL</button>
            </div>
        }
        <div className="products">
            {
                products.map((product, index) => {
                    return (<ProductItem key={product._id} product={product} 
                    isAdmin={isAdmin} deleteProduct={deleteProduct} handleCheck={handleCheck}/>)
                })
            }
            
        </div>
        <LoadMore />
        {products.length === 0 && <Loading />}
        </>
    )
}

export default Products
