import React, {useState, useContext} from 'react';  
import { categories } from "../data";
import styled from "styled-components";
import { GlobalState } from '../../../../GlobalState';
import { mobile } from "../responsive";
import CategoryItem from "./CategoryItem";

const Container = styled.div`
  display: flex;
  padding: 20px;
  justify-content: space-between;
  ${mobile({ padding: "0px", flexDirection:"row" })}
`;

const Categories = () => {
    const state = useContext(GlobalState)
    let [category] = state.categoriesAPI.categories;

    // if(category.length){
    //   category = category.slice(0, 6);
    // }

    return (
        <Container>
        {/* {category.map((item) => (
          <CategoryItem  item={item} key={item.id} />
        ))} */}
        {
          category.map(item => {
            console.log(item.name);
            if(item.name == "Жилетки" || item.name == "Рокли" || item.name == "Блузи" || item.name == "Панталони") {
              return (<CategoryItem  item={item} key={item.id} />)
            }
          })
        }
      </Container>
    )
}

export default Categories
