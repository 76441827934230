import React, { useState, useContext, useEffect } from 'react'
import axios from 'axios';
import { GlobalState } from '../../../GlobalState';
import Loading from '../utils/loading/Loading';
import { useHistory, useParams } from 'react-router-dom';

const tableState = {
    xs_go: '',
    s_go: '',
    m_go: '',
    l_go: '',
    xl_go: '',
    xxl_go: '',
    xxxl_go:'',
    xxxxl_go:'',
    xxxxxl_go:'',
    xs_t: '',
    s_t: '',
    m_t: '',
    l_t: '',
    xl_t: '',
    xxl_t: '',
    xxxl_t:'',
    xxxxl_t:'',
    xxxxxl_t:'',
    xs_h: '',
    s_h: '',
    m_h: '',
    l_h: '',
    xl_h: '',
    xxl_h: '',
    xxxl_h:'',
    xxxxl_h:'',
    xxxxxl_h:''
}

const initialState = {
    product_id: '',
    title: '',
    colors: 'бял, зелен, червен, оранжев',
    size: 'XS, S, M, L, XL, XXL, XXXL, XXXL',
    gender: '',
    sold_out: '',
    price: 0,
    sale: 0,
    description: 'Описание на продукта',
    content: 'Съдържание на продукта',
    category: '',
    _id: ''
}


const CreateProduct = () => {
    const state = useContext(GlobalState);
    const [product, setProduct] = useState(initialState);
    const [table, setTable] = useState(tableState);
    const [categories] = state.categoriesAPI.categories;
    const [images, setImages] = useState(false);
    const [imagesTwo, setImagesTwo] = useState(false);
    const [imagesThree, setImagesThree] = useState(false);
    const [imagesFour, setImagesFour] = useState(false);
    const [imagesFive, setImagesFive] = useState(false);
    const [imagesSix, setImagesSix] = useState(false);
    const [imagesSeven, setImagesSeven] = useState(false);
    const [imagesEight, setImagesEight] = useState(false);
    const [imagesNine, setImagesNine] = useState(false);
    const [imagesTen, setImagesTen] = useState(false);
    const [imagesEleven, setImagesEleven] = useState(false);
    const [imagesTwelve, setImagesTwelve] = useState(false);
    const [loading, setLoading] = useState(false);
    const [loadingTwo, setLoadingTwo] = useState(false);
    const [loadingThree, setLoadingThree] = useState(false);
    const [loadingFour, setLoadingFour] = useState(false);
    const [loadingFive, setLoadingFive] = useState(false);
    const [loadingSix, setLoadingSix] = useState(false);
    const [loadingSeven, setLoadingSeven] = useState(false);
    const [loadingEight, setLoadingEight] = useState(false);
    const [loadingNine, setLoadingNine] = useState(false);
    const [loadingTen, setLoadingTen] = useState(false);
    const [loadingEleven, setLoadingEleven] = useState(false);
    const [loadingTwelve, setLoadingTwelve] = useState(false);

    console.log(product)
    const [isAdmin] = state.userAPI.isAdmin
    const [token] = state.token

    const history = useHistory();
    const param = useParams();

    const [products] = state.productsAPI.products;
    const [onEdit, setOnEdit] = useState(false);
    const [callback, setCallback] = state.productsAPI.callback;

    //edit product
    useEffect(() => {
        if (param.id) {
            setOnEdit(true);
            products.forEach(product => {
                if (product._id === param.id) {
                    setProduct(product);
                    setImages(product.images);
                    setImagesTwo(product.imagesTwo);
                    setImagesThree(product.imagesThree);
                    setImagesFour(product.imagesFour);
                    setImagesFive(product.imagesFive);
                    setImagesSix(product.imagesSix);
                    setImagesSeven(product.imagesSeven);
                    setImagesEight(product.imagesEight);
                    setImagesNine(product.imagesNine);
                    setImagesTen(product.imagesTen);
                    setImagesEleven(product.imagesEleven);
                    setImagesTwelve(product.imagesTwelve);
                    setTable(product.table);
                }
            });

        } else {
            setOnEdit(false);
            setProduct(initialState);
            setTable(table);
            setImages(false);
            setImagesTwo(false);
            setImagesThree(false);
            setImagesFour(false);
            setImagesFive(false);
            setImagesSix(false);
            setImagesSeven(false);
            setImagesEight(false);
            setImagesNine(false);
            setImagesTen(false);
            setImagesEleven(false);
            setImagesTwelve(false);
        }
    }, [param.id])


    const handleUpload = async e => {
        e.preventDefault()
        try {
            if (!isAdmin) return alert("Вие не сте Администратор")
            const file = e.target.files[0]

            if (!file) return alert("File not exist.")

            if (file.size > 1024 * 1024) // 1mb
                return alert("Размерът на картинката е твърде голям!")

            if (file.type !== 'image/jpeg' && file.type !== 'image/png') // 1mb
                return alert("Форматът на снимката трябва да е jpeg, jpg, или png")

            let formData = new FormData()
            formData.append('file', file)

            setLoading(true)
            const res = await axios.post('/api/upload', formData, {
                headers: { 'content-type': 'multipart/form-data', Authorization: token }
            })

            setLoading(false)
            setImages(res.data)

        } catch (err) {
            alert(err.response.data.msg)
        }
    }

    const handleUploadTwo = async e => {
        e.preventDefault()
        try {
            if (!isAdmin) return alert("Вие не сте Администратор")
            const file = e.target.files[0]

            if (!file) return alert("File not exist.")

            if (file.size > 1024 * 1024) // 1mb
                return alert("Size too large!")

            if (file.type !== 'image/jpeg' && file.type !== 'image/png') // 1mb
                return alert("File format is incorrect.")

            let formData = new FormData()
            formData.append('file', file)

            setLoadingTwo(true)
            const res = await axios.post('/api/upload', formData, {
                headers: { 'content-type': 'multipart/form-data', Authorization: token }
            })

            setLoadingTwo(false)
            setImagesTwo(res.data)

        } catch (err) {
            alert(err.response.data.msg)
        }
    }

    const handleUploadThree = async e => {
        e.preventDefault()
        try {
            if (!isAdmin) return alert("Вие не сте Администратор")
            const file = e.target.files[0]

            if (!file) return alert("File not exist.")

            if (file.size > 1024 * 1024) // 1mb
                return alert("Size too large!")

            if (file.type !== 'image/jpeg' && file.type !== 'image/png') // 1mb
                return alert("File format is incorrect.")

            let formData = new FormData()
            formData.append('file', file)

            setLoadingThree(true)
            const res = await axios.post('/api/upload', formData, {
                headers: { 'content-type': 'multipart/form-data', Authorization: token }
            })

            setLoadingThree(false)
            setImagesThree(res.data)

        } catch (err) {
            alert(err.response.data.msg)
        }
    }

    const handleUploadFour = async e => {
        e.preventDefault()
        try {
            if (!isAdmin) return alert("Вие не сте администратор")
            const file = e.target.files[0]

            if (!file) return alert("Не сте добавили снимка")

            if (file.size > 1024 * 1024) // 1mb
                return alert("Size too large!")

            if (file.type !== 'image/jpeg' && file.type !== 'image/png') // 1mb
                return alert("File format is incorrect.")

            let formData = new FormData()
            formData.append('file', file)

            setLoadingFour(true)
            const res = await axios.post('/api/upload', formData, {
                headers: { 'content-type': 'multipart/form-data', Authorization: token }
            })

            setLoadingFour(false)
            setImagesFour(res.data)

        } catch (err) {
            alert(err.response.data.msg)
        }
    }

    const handleUploadFive = async e => {
        e.preventDefault()
        try {
            if (!isAdmin) return alert("Вие не сте администратор")
            const file = e.target.files[0]

            if (!file) return alert("Не сте добавили снимка")

            if (file.size > 1024 * 1024) // 1mb
                return alert("Size too large!")

            if (file.type !== 'image/jpeg' && file.type !== 'image/png') // 1mb
                return alert("Форматът на снимката трябав да е png или jpeg.")

            let formData = new FormData()
            formData.append('file', file)

            setLoadingFive(true)
            const res = await axios.post('/api/upload', formData, {
                headers: { 'content-type': 'multipart/form-data', Authorization: token }
            })

            setLoadingFive(false)
            setImagesFive(res.data)

        } catch (err) {
            alert(err.response.data.msg)
        }
    }

    const handleUploadSix = async e => {
        e.preventDefault()
        try {
            if (!isAdmin) return alert("Вие не сте администратор")
            const file = e.target.files[0]

            if (!file) return alert("Не сте добавили снимка")

            if (file.size > 1024 * 1024) // 1mb
                return alert("Size too large!")

            if (file.type !== 'image/jpeg' && file.type !== 'image/png') // 1mb
                return alert("Форматът на снимката трябав да е png или jpeg.")

            let formData = new FormData()
            formData.append('file', file)

            setLoadingSix(true)
            const res = await axios.post('/api/upload', formData, {
                headers: { 'content-type': 'multipart/form-data', Authorization: token }
            })

            setLoadingSix(false)
            setImagesSix(res.data)

        } catch (err) {
            alert(err.response.data.msg)
        }
    }

    const handleUploadSeven = async e => {
        e.preventDefault()
        try {
            if (!isAdmin) return alert("Вие не сте администратор")
            const file = e.target.files[0]

            if (!file) return alert("Не сте добавили снимка")

            if (file.size > 1024 * 1024) // 1mb
                return alert("Size too large!")

            if (file.type !== 'image/jpeg' && file.type !== 'image/png') // 1mb
                return alert("Форматът на снимката трябав да е png или jpeg.")

            let formData = new FormData()
            formData.append('file', file)

            setLoadingSeven(true)
            const res = await axios.post('/api/upload', formData, {
                headers: { 'content-type': 'multipart/form-data', Authorization: token }
            })

            setLoadingSeven(false)
            setImagesSeven(res.data)

        } catch (err) {
            alert(err.response.data.msg)
        }
    }

    const handleUploadEight = async e => {
        e.preventDefault()
        try {
            if (!isAdmin) return alert("Вие не сте администратор")
            const file = e.target.files[0]

            if (!file) return alert("Не сте добавили снимка")

            if (file.size > 1024 * 1024) // 1mb
                return alert("Size too large!")

            if (file.type !== 'image/jpeg' && file.type !== 'image/png') // 1mb
                return alert("Форматът на снимката трябав да е png или jpeg.")

            let formData = new FormData()
            formData.append('file', file)

            setLoadingEight(true)
            const res = await axios.post('/api/upload', formData, {
                headers: { 'content-type': 'multipart/form-data', Authorization: token }
            })

            setLoadingEight(false)
            setImagesEight(res.data)

        } catch (err) {
            alert(err.response.data.msg)
        }
    }

    const handleUploadNine = async e => {
        e.preventDefault()
        try {
            if (!isAdmin) return alert("Вие не сте администратор")
            const file = e.target.files[0]

            if (!file) return alert("Не сте добавили снимка")

            if (file.size > 1024 * 1024) // 1mb
                return alert("Size too large!")

            if (file.type !== 'image/jpeg' && file.type !== 'image/png') // 1mb
                return alert("Форматът на снимката трябав да е png или jpeg.")

            let formData = new FormData()
            formData.append('file', file)

            setLoadingNine(true)
            const res = await axios.post('/api/upload', formData, {
                headers: { 'content-type': 'multipart/form-data', Authorization: token }
            })

            setLoadingNine(false)
            setImagesNine(res.data)

        } catch (err) {
            alert(err.response.data.msg)
        }
    }

    const handleUploadTen = async e => {
        e.preventDefault()
        try {
            if (!isAdmin) return alert("Вие не сте администратор")
            const file = e.target.files[0]

            if (!file) return alert("Не сте добавили снимка")

            if (file.size > 1024 * 1024) // 1mb
                return alert("Size too large!")

            if (file.type !== 'image/jpeg' && file.type !== 'image/png') // 1mb
                return alert("Форматът на снимката трябав да е png или jpeg.")

            let formData = new FormData()
            formData.append('file', file)

            setLoadingTen(true)
            const res = await axios.post('/api/upload', formData, {
                headers: { 'content-type': 'multipart/form-data', Authorization: token }
            })

            setLoadingTen(false)
            setImagesTen(res.data)

        } catch (err) {
            alert(err.response.data.msg)
        }
    }

    const handleUploadEleven = async e => {
        e.preventDefault()
        try {
            if (!isAdmin) return alert("Вие не сте администратор")
            const file = e.target.files[0]

            if (!file) return alert("Не сте добавили снимка")

            if (file.size > 1024 * 1024) // 1mb
                return alert("Size too large!")

            if (file.type !== 'image/jpeg' && file.type !== 'image/png') // 1mb
                return alert("Форматът на снимката трябав да е png или jpeg.")

            let formData = new FormData()
            formData.append('file', file)

            setLoadingEleven(true)
            const res = await axios.post('/api/upload', formData, {
                headers: { 'content-type': 'multipart/form-data', Authorization: token }
            })

            setLoadingEleven(false)
            setImagesEleven(res.data)

        } catch (err) {
            alert(err.response.data.msg)
        }
    }

    const handleUploadTwelve = async e => {
        e.preventDefault()
        try {
            if (!isAdmin) return alert("Вие не сте администратор")
            const file = e.target.files[0]

            if (!file) return alert("Не сте добавили снимка")

            if (file.size > 1024 * 1024) // 1mb
                return alert("Size too large!")

            if (file.type !== 'image/jpeg' && file.type !== 'image/png') // 1mb
                return alert("Форматът на снимката трябав да е png или jpeg.")

            let formData = new FormData()
            formData.append('file', file)

            setLoadingTwelve(true)
            const res = await axios.post('/api/upload', formData, {
                headers: { 'content-type': 'multipart/form-data', Authorization: token }
            })

            setLoadingTwelve(false)
            setImagesTwelve(res.data)

        } catch (err) {
            alert(err.response.data.msg)
        }
    }

    const handleDestroy = async () => {
        try {
            if (!isAdmin) return alert("Вие не сте администратор!")
            setLoading(true)
            await axios.post('/api/destroy', { public_id: images.public_id }, {
                headers: { Authorization: token }
            })
            setLoading(false)
            setImages(false)
        } catch (err) {
            alert(err.response.data.msg)
        }
    }

    const handleDestroyTwo = async () => {
        try {
            if (!isAdmin) return alert("Вие не сте администратор!")
            setLoadingTwo(true)
            await axios.post('/api/destroy', { public_id: imagesTwo.public_id }, {
                headers: { Authorization: token }
            })
            setLoadingTwo(false)
            setImagesTwo(false)
        } catch (err) {
            alert(err.response.data.msg)
        }
    }

    const handleDestroyThree = async () => {
        try {
            if (!isAdmin) return alert("Вие не сте администратор!")
            setLoadingThree(true)
            await axios.post('/api/destroy', { public_id: imagesThree.public_id }, {
                headers: { Authorization: token }
            })
            setLoadingThree(false)
            setImagesThree(false)
        } catch (err) {
            alert(err.response.data.msg)
        }
    }

    const handleDestroyFour = async () => {
        try {
            if (!isAdmin) return alert("Вие не сте администратор!")
            setLoadingFour(true)
            await axios.post('/api/destroy', { public_id: imagesFour.public_id }, {
                headers: { Authorization: token }
            })
            setLoadingFour(false)
            setImagesFour(false)
        } catch (err) {
            alert(err.response.data.msg)
        }
    }

    const handleDestroyFive = async () => {
        try {
            if (!isAdmin) return alert("Вие не сте администратор!")
            setLoadingFive(true)
            await axios.post('/api/destroy', { public_id: imagesFive.public_id }, {
                headers: { Authorization: token }
            })
            setLoadingFive(false)
            setImagesFive(false)
        } catch (err) {
            alert(err.response.data.msg)
        }
    }

    const handleDestroySix = async () => {
        try {
            if (!isAdmin) return alert("Вие не сте администратор!")
            setLoadingSix(true)
            await axios.post('/api/destroy', { public_id: imagesSix.public_id }, {
                headers: { Authorization: token }
            })
            setLoadingSix(false)
            setImagesSix(false)
        } catch (err) {
            alert(err.response.data.msg)
        }
    }

    const handleDestroySeven = async () => {
        try {
            if (!isAdmin) return alert("Вие не сте администратор!")
            setLoadingSeven(true)
            await axios.post('/api/destroy', { public_id: imagesSeven.public_id }, {
                headers: { Authorization: token }
            })
            setLoadingSeven(false)
            setImagesSeven(false)
        } catch (err) {
            alert(err.response.data.msg)
        }
    }

    const handleDestroyEight = async () => {
        try {
            if (!isAdmin) return alert("Вие не сте администратор!")
            setLoadingEight(true)
            await axios.post('/api/destroy', { public_id: imagesEight.public_id }, {
                headers: { Authorization: token }
            })
            setLoadingEight(false)
            setImagesEight(false)
        } catch (err) {
            alert(err.response.data.msg)
        }
    }

    const handleDestroyNine = async () => {
        try {
            if (!isAdmin) return alert("Вие не сте администратор!")
            setLoadingNine(true)
            await axios.post('/api/destroy', { public_id: imagesNine.public_id }, {
                headers: { Authorization: token }
            })
            setLoadingNine(false)
            setImagesNine(false)
        } catch (err) {
            alert(err.response.data.msg)
        }
    }

    const handleDestroyTen = async () => {
        try {
            if (!isAdmin) return alert("Вие не сте администратор!")
            setLoadingTen(true)
            await axios.post('/api/destroy', { public_id: imagesTen.public_id }, {
                headers: { Authorization: token }
            })
            setLoadingTen(false)
            setImagesTen(false)
        } catch (err) {
            alert(err.response.data.msg)
        }
    }

    const handleDestroyEleven = async () => {
        try {
            if (!isAdmin) return alert("Вие не сте администратор!")
            setLoadingEleven(true)
            await axios.post('/api/destroy', { public_id: imagesEleven.public_id }, {
                headers: { Authorization: token }
            })
            setLoadingEleven(false)
            setImagesEleven(false)
        } catch (err) {
            alert(err.response.data.msg)
        }
    }

    const handleDestroyTwelve = async () => {
        try {
            if (!isAdmin) return alert("Вие не сте администратор!")
            setLoadingTwelve(true)
            await axios.post('/api/destroy', { public_id: imagesTwelve.public_id }, {
                headers: { Authorization: token }
            })
            setLoadingTwelve(false)
            setImagesTwelve(false)
        } catch (err) {
            alert(err.response.data.msg)
        }
    }

    const handleChangeInput = e => {
        const { name, value } = e.target
        setProduct({ ...product, [name]: value })
        console.log(product);
    }

    const handleChangeTable = e => {
        const { name, value } = e.target
        setTable({ ...table, [name]: value })
    }

    const handleSubmit = async e => {
        e.preventDefault()
        try {
            if (!isAdmin) return alert("Вие не сте администратор");
            if (!images) return alert("No Image Upload");
            //if (!imagesTwo) return alert("No Image Upload")
           // if (!imagesThree) return alert("No Image Upload")
            //if (!imagesFour) return alert("No Image Upload")

            if (onEdit) {
                await axios.put(`/api/products/${product._id}`, { ...product, table, images, imagesTwo, imagesThree, imagesFour, imagesFive, imagesSix, imagesSeven, imagesEight, imagesNine, imagesTen, imagesEleven, imagesTwelve }, {
                    headers: { Authorization: token }
                });
            } else {
                await axios.post(`/api/products/`, { ...product, table, images, imagesTwo, imagesThree, imagesFour, imagesFive, imagesSix, imagesSeven, imagesEight, imagesNine, imagesTen, imagesEleven, imagesTwelve }, {
                    headers: { Authorization: token }
                });
            }
            setCallback(!callback);

            setProduct(initialState);

            history.push("/products");


        } catch (err) {
            alert(err.response.data.msg)
        }
    }


    const styleUpload = {
        display: images ? "block" : "none"
    }

    const styleUploadTwo = {
        display: imagesTwo ? "block" : "none"
    }

    const styleUploadThree = {
        display: imagesThree ? "block" : "none"
    }

    const styleUploadFour = {
        display: imagesFour ? "block" : "none"
    }

    const styleUploadFive = {
        display: imagesFive ? "block" : "none"
    }

    const styleUploadSix = {
        display: imagesSix ? "block" : "none"
    }

    const styleUploadSeven = {
        display: imagesSeven ? "block" : "none"
    }

    const styleUploadEight = {
        display: imagesEight ? "block" : "none"
    }

    const styleUploadNine = {
        display: imagesNine ? "block" : "none"
    }

    const styleUploadTen = {
        display: imagesTen ? "block" : "none"
    }

    const styleUploadEleven = {
        display: imagesEleven ? "block" : "none"
    }

    const styleUploadTwelve = {
        display: imagesTwelve ? "block" : "none"
    }


    return (
        <div className="create_product">
            <div className="upload">
                <input type="file" name="file" id="file_up_one" onChange={handleUpload} />
                {
                    loading ? <div id="file_img"><Loading /></div>

                        : <div id="file_img" style={styleUpload}>
                            <img src={images ? `https://lh3.googleusercontent.com/d/${images.url.split("id=")[1]}=s500?authuser=1` : ''} alt="" referrerpolicy="no-referrer" />
                            <span onClick={handleDestroy}>X</span>
                        </div>
                }

            </div>

            <div className="upload">
                <input type="file" name="file" id="file_up_two" onChange={handleUploadTwo} />
                {
                    loadingTwo ? <div id="file_img"><Loading /></div>

                        : <div id="file_img" style={styleUploadTwo}>
                            <img src={imagesTwo ? `https://lh3.googleusercontent.com/d/${imagesTwo.url.split("id=")[1]}=s500?authuser=1` : ''} alt="" referrerpolicy="no-referrer" />
                            <span onClick={handleDestroyTwo}>X</span>
                        </div>
                }
            </div>

            <div className="upload">
                <input type="file" name="file" id="file_up_three" onChange={handleUploadThree} />
                {
                    loadingThree ? <div id="file_img"><Loading /></div>

                        : <div id="file_img" style={styleUploadThree}>
                            <img src={imagesThree ? `https://lh3.googleusercontent.com/d/${imagesThree.url.split("id=")[1]}=s500?authuser=1` : ''} alt="" referrerpolicy="no-referrer" />
                            <span onClick={handleDestroyThree}>X</span>
                        </div>
                }
            </div>

            <div className="upload">
                <input type="file" name="file" id="file_up_four" onChange={handleUploadFour} />
                {
                    loadingFour ? <div id="file_img"><Loading /></div>

                        : <div id="file_img" style={styleUploadFour}>
                            <img src={imagesFour ? `https://lh3.googleusercontent.com/d/${imagesFour.url.split("id=")[1]}=s500?authuser=1` : ''} alt="" referrerpolicy="no-referrer" />
                            <span onClick={handleDestroyFour}>X</span>
                        </div>
                }
            </div>

            <div className="upload">
                <input type="file" name="file" id="file_up_five" onChange={handleUploadFive} />
                {
                    loadingFive ? <div id="file_img"><Loading /></div>

                        : <div id="file_img" style={styleUploadFive}>
                            <img src={imagesFive ? `https://lh3.googleusercontent.com/d/${imagesFive.url.split("id=")[1]}=s500?authuser=1` : ''} alt="" referrerpolicy="no-referrer"/>
                            <span onClick={handleDestroyFive}>X</span>
                        </div>
                }
            </div>

            <div className="upload">
                <input type="file" name="file" id="file_up_six" onChange={handleUploadSix} />
                {
                    loadingSix ? <div id="file_img"><Loading /></div>

                        : <div id="file_img" style={styleUploadSix}>
                            <img src={imagesSix ? `https://lh3.googleusercontent.com/d/${imagesSix.url.split("id=")[1]}=s500?authuser=1` : ''} alt="" referrerpolicy="no-referrer" />
                            <span onClick={handleDestroySix}>X</span>
                        </div>
                }
            </div>

            <div className="upload">
                <input type="file" name="file" id="file_up_seven" onChange={handleUploadSeven} />
                {
                    loadingSeven ? <div id="file_img"><Loading /></div>

                        : <div id="file_img" style={styleUploadSeven}>
                            <img src={imagesSeven ? `https://lh3.googleusercontent.com/d/${imagesSeven.url.split("id=")[1]}=s500?authuser=1` : ''} alt="" referrerpolicy="no-referrer"/>
                            <span onClick={handleDestroySeven}>X</span>
                        </div>
                }
            </div>

            <div className="upload">
                <input type="file" name="file" id="file_up_eight" onChange={handleUploadEight} />
                {
                    loadingEight ? <div id="file_img"><Loading /></div>

                        : <div id="file_img" style={styleUploadEight}>
                            <img src={imagesEight ? `https://lh3.googleusercontent.com/d/${imagesEight.url.split("id=")[1]}=s500?authuser=1` : ''} alt="" referrerpolicy="no-referrer" />
                            <span onClick={handleDestroyEight}>X</span>
                        </div>
                }
            </div>

            <div className="upload">
                <input type="file" name="file" id="file_up_nine" onChange={handleUploadNine} />
                {
                    loadingNine ? <div id="file_img"><Loading /></div>

                        : <div id="file_img" style={styleUploadNine}>
                            <img src={imagesNine ?  `https://lh3.googleusercontent.com/d/${imagesNine.url.split("id=")[1]}=s500?authuser=1` : ''} alt="" referrerpolicy="no-referrer" />
                            <span onClick={handleDestroyNine}>X</span>
                        </div>
                }
            </div>

            <div className="upload">
                <input type="file" name="file" id="file_up_ten" onChange={handleUploadTen} />
                {
                    loadingTen ? <div id="file_img"><Loading /></div>

                        : <div id="file_img" style={styleUploadTen}>
                            <img src={imagesTen ? `https://lh3.googleusercontent.com/d/${imagesTen.url.split("id=")[1]}=s500?authuser=1` : ''} alt="" referrerpolicy="no-referrer" />
                            <span onClick={handleDestroyTen}>X</span>
                        </div>
                }
            </div>

            <div className="upload">
                <input type="file" name="file" id="file_up_eleven" onChange={handleUploadEleven} />
                {
                    loadingEleven ? <div id="file_img"><Loading /></div>

                        : <div id="file_img" style={styleUploadEleven}>
                    <img src={imagesEleven ? `https://lh3.googleusercontent.com/d/${imagesEleven.url.split("id=")[1]}=s500?authuser=1` : ''} alt="" referrerpolicy="no-referrer" />

                            <span onClick={handleDestroyEleven}>X</span>
                        </div>
                }
            </div>


            
            <div className="upload">
                <input type="file" name="file" id="file_up_twelve" onChange={handleUploadTwelve} />
                {
                    loadingTwelve ? <div id="file_img"><Loading /></div>

                        : <div id="file_img" style={styleUploadTwelve}>
                        <img src={imagesTwelve ? `https://lh3.googleusercontent.com/d/${imagesTwelve.url.split("id=")[1]}=s500?authuser=1` : ''} alt="" referrerpolicy="no-referrer" />

                            <span onClick={handleDestroyTwelve}>X</span>
                        </div>
                }
            </div>

            <div className="upload" style={{backgroundColor: 'red', display: 'inline-block'}}>
                <input type="file" style={{backgroundColor: 'red'}} name="file" id="file_up_twelve" onChange={handleUploadTwelve} disabled={true}/>
                {
                    false ? <div id="file_img"><Loading /></div>

                        : <div id="file_img" style={styleUploadTwelve} >
                            {/* <img src={imagesTwelve ? imagesTwelve.url : ''} alt="" /> */}
                            <span onClick={handleDestroyTwelve}>X</span>
                        </div>
                }
            </div>

            <form onSubmit={handleSubmit}>
                <div className="row">
                    <label htmlFor="product_id">Код на продукта</label>
                    <input type="text" name="product_id" id="product_id"
                        value={product.product_id} onChange={handleChangeInput} disabled={onEdit} />
                </div>

                <div className="row">
                    <label htmlFor="title">Заглавие на продукта</label>
                    <input type="text" name="title" id="title"
                        value={product.title} onChange={handleChangeInput} />
                </div>

                <div className="row">
                    <label htmlFor="price">Цена на продукта</label>
                    <input type="number" name="price" id="price"
                        value={product.price} onChange={handleChangeInput} />
                </div>

                <div className="row">
                    <label htmlFor="price">Стара цена</label>
                    <input type="number" name="sale" id="sale"
                        value={product.sale} onChange={handleChangeInput} />
                </div>
                <div className="row">
                    <input className="radio_male" name="gender" type="radio" checked={product.gender == "male"}
                        onChange={handleChangeInput} value="male" /> Male

                    <input className="radio_male" name="gender" type="radio" checked={product.gender == "famale"}
                        onChange={handleChangeInput} value="famale" /> Famale

                </div>

                <div className="row">
                    <input className="radio_male" name="sold_out" type="radio" checked={product.sold_out == "sold_out"}
                        onChange={handleChangeInput} value="sold_out" /> Изчерпан

                    <input className="radio_male" name="sold_out" type="radio" checked={product.sold_out == "sold_out_not"}
                        onChange={handleChangeInput} value="sold_out_not" /> Не е изчерпан
                </div>

                <div className="row">

                    <label htmlFor="colors">Цветове</label>
                    <input type="text" name="colors" id="color"
                        value={product.colors} onChange={handleChangeInput} />
                </div>

                <div className="row">

                    <label htmlFor="title">Размери</label>
                    <input type="text" name="size" id="size"
                        value={product.size} onChange={handleChangeInput} />
                </div>

                <div className="row">
                    <label htmlFor="description">Описание</label>
                    <textarea type="text" name="description" id="description" required
                        value={product.description} rows="5" onChange={handleChangeInput} />
                </div>

                <div className="row">
                    <label htmlFor="content">Съдържание</label>
                    <textarea type="text" name="content" id="content" required
                        value={product.content} rows="7" onChange={handleChangeInput} />
                </div>

                <div className="row">
                    <label htmlFor="">Таблица</label>
                        <table>
                            <tr>
                                <th></th>
                                <th>XS</th>
                                <th>S</th>
                                <th>M</th>
                                <th>L</th>
                                <th>XL</th>
                                <th>2XL</th>
                                <th>3XL</th>
                                <th>4XL</th>
                                <th>5XL</th>
                            </tr>
                            <tr>
                                <td>Г. O</td>
                                <td><input value={table.xs_go} name="xs_go" id="xs_go" onChange={handleChangeTable} type="text" /></td>
                                <td><input value={table.s_go} name="s_go" id="s_go" onChange={handleChangeTable} type="text" /></td>
                                <td><input value={table.m_go} name="m_go" id="m_go" onChange={handleChangeTable} type="text" /></td>
                                <td><input value={table.l_go} name="l_go" id="l_go" onChange={handleChangeTable} type="text" /></td>
                                <td><input value={table.xl_go} name="xl_go" id="xl_go" onChange={handleChangeTable} type="text" /></td>
                                <td><input value={table.xxl_go} name="xxl_go" id="xxl_go" onChange={handleChangeTable} type="text" /></td>
                                <td><input value={table.xxxl_go} name="xxxl_go" id="xxxl_go" onChange={handleChangeTable} type="text" /></td>
                                <td><input value={table.xxxxl_go} name="xxxxl_go" id="xxxxl_go" onChange={handleChangeTable} type="text" /></td>
                                <td><input value={table.xxxxxl_go} name="xxxxxl_go" id="xxxxxl_go" onChange={handleChangeTable} type="text" /></td>
                            </tr>
                            <tr>
                                <td>Талия</td>
                                <td><input value={table.xs_t} name="xs_t" id="xs_t" onChange={handleChangeTable} type="text" /></td>
                                <td><input value={table.s_t} name="s_t" id="s_t" onChange={handleChangeTable} type="text" /></td>
                                <td><input value={table.m_t} name="m_t" id="m_t" onChange={handleChangeTable} type="text" /></td>
                                <td><input value={table.l_t} name="l_t" id="l_t" onChange={handleChangeTable} type="text" /></td>
                                <td><input value={table.xl_t} name="xl_t" id="xl_t" onChange={handleChangeTable} type="text" /></td>
                                <td><input value={table.xxl_t} name="xxl_t" id="xxl_t" onChange={handleChangeTable} type="text" /></td>
                                <td><input value={table.xxxl_t} name="xxxl_t" id="xxxl_t" onChange={handleChangeTable} type="text" /></td>
                                <td><input value={table.xxxxl_t} name="xxxxl_t" id="xxxxl_t" onChange={handleChangeTable} type="text" /></td>
                                <td><input value={table.xxxxxl_t} name="xxxxxl_t" id="xxxxxl_t" onChange={handleChangeTable} type="text" /></td>

                            </tr>
                            <tr>
                                <td>Ханш</td>
                                <td><input value={table.xs_h} name="xs_h" id="xs_h" onChange={handleChangeTable} type="text" /></td>
                                <td><input value={table.s_h} name="s_h" id="s_h" onChange={handleChangeTable} type="text" /></td>
                                <td><input value={table.m_h} name="m_h" id="m_h" onChange={handleChangeTable} type="text" /></td>
                                <td><input value={table.l_h} name="l_h" id="l_h" onChange={handleChangeTable} type="text" /></td>
                                <td><input value={table.xl_h} name="xl_h" id="xl_h" onChange={handleChangeTable} type="text" /></td>
                                <td><input value={table.xxl_h} name="xxl_h" id="xxl_h" onChange={handleChangeTable} type="text" /></td>
                                <td><input value={table.xxxl_h} name="xxxl_h" id="xxxl_h" onChange={handleChangeTable} type="text" /></td>
                                <td><input value={table.xxxxl_h} name="xxxxl_h" id="xxxxl_h" onChange={handleChangeTable} type="text" /></td>
                                <td><input value={table.xxxxxl_h} name="xxxxxl_h" id="xxxxxl_h" onChange={handleChangeTable} type="text" /></td>
                            </tr>
                        </table>
                </div>

                <div className="row">
                    <label htmlFor="categories">Категории: </label>
                    <select name="category" value={product.category} onChange={handleChangeInput} >
                        <option value="">Моля изберете категория</option>
                        {
                            categories.map(category => (
                                <option value={category._id} key={category._id}>
                                    {category.name}
                                </option>
                            ))
                        }
                    </select>
                </div>

                <button type="submit">{onEdit ? "Update" : "Create"}</button>
            </form>
        </div>
    )
}

export default CreateProduct
