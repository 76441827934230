import React, {useContext} from 'react';
import {GlobalState} from '../../../GlobalState';

const Filters = () => {
    const state = useContext(GlobalState);
    const [categories] = state.categoriesAPI.categories

    const [category, setCategory] = state.productsAPI.category
    const [size, setSize] = state.productsAPI.size
    const [sort, setSort] = state.productsAPI.sort
    const [search, setSearch] = state.productsAPI.search

    const handleCategory = e => {
        setCategory(e.target.value)
        setSearch('')
    }

    const handleSize = e => {
        setSize(e.target.value)
        setSearch('')
    }
 
    return (
        <div className="filter_menu">
            <div className="row">
                <span>Категории: </span>
                <select name="category" id="" value={category} onChange={handleCategory}>
                    <option value="">Всички Продукти</option>
                    {
                        categories.map(category => (
                            <option value={"category=" + category._id} key={category._id}>
                                {category.name}
                            </option>
                        ))
                    }
                </select>
            </div>

            <div className="row">
                <span>Размери: </span>
                <select name="category" id="" value={size}  onChange={handleSize}>
                    <option value="">Всички Размери</option>
                    <option value={"size=" + "S"}>S</option>
                    <option value={"size=" + 'M'}>M</option>
                    <option value={"size=" + 'L'}>L</option>
                    <option value={"size=" + 'XL'}>XL</option>
                    <option value={"size=" + 'XXL'}>XXL</option>
                </select>
            </div>

            <input type="text" value={search} placeholder="Търсене..."
            onChange={e => setSearch(e.target.value.toLowerCase())} />

            <div className="row sort">
                <span>Сортиурай по: </span>
                <select value={sort} onChange={e => setSort(e.target.value)} >
                    <option value=''>Най - новият</option>
                    <option value='sort=oldest'>Най - старият</option>
                    <option value='sort=-sold'>Най - много продажби</option>
                    <option value='sort=-price'>Цена: Висока - ниска</option>
                    <option value='sort=price'>Цена: Ниска - Висока</option>
                </select>
            </div>

        </div>
    )
}

export default Filters
