import React,{useEffect} from 'react';
import BtnRender from './BtnRender';
import { Link } from 'react-router-dom';
import axios from 'axios';


const ProductItem = ({product, isAdmin, deleteProduct, handleCheck}) => {

    const words = product.images.url.split("id=")
   
    return (
        <Link to={`/detail/${product._id}`}  className="product_card">
          
            {
                isAdmin && <input type="checkbox" checked={product.checked} 
                onChange={() => handleCheck(product._id)}/>
            }
            <img style={product.sold_out === "sold_out" ? {filter: "grayscale(100%)"} : {}}  src={`https://lh3.googleusercontent.com/d/${words[1]}=s500?authuser=1`} referrerpolicy="no-referrer" alt="" /> 
            {/* <img style={product.sold_out === "sold_out" ? {filter: "grayscale(100%)"} : {}}  src={`https://drive.google.com/thumbnail?id=/${words[1]}&sz=w500?authuser=0`} alt="" /> */}
            <div style={product.sale ? {backgroundColor: ""} : { backgroundColor: "transparent", border: "transparent"} } className="sale_procent">{product.sale ? "-" + Number(((product.sale - product.price) / product.sale) * 100).toFixed(0) + "%" : ""}</div>

            <div className="product_box">
                <h2>{product.title}</h2>
                <span>{Number(product.price).toFixed(2)} лв.</span>
                <span className="sale" >{product.sale ? product.sale + " лв." : ""}</span>
                <p>{product.description}</p>
            </div>

           {
               isAdmin ? <BtnRender product={product} deleteProduct={deleteProduct} /> : null
           }
            
        </Link>
    )
}

export default ProductItem
