import React, { useContext, useState } from 'react'
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Link } from 'react-router-dom'
import { Carousel } from 'react-responsive-carousel';
import { GlobalState } from '../../../../GlobalState';



const Slider = () => {
  const [intervalz, setIntervalz] = useState(3000);
  const state = useContext(GlobalState);
  const [banners] = state.bannersAPI.banners;

  console.log(banners);
  const onChange = (index, item) => {
    setIntervalz(item.props["data-interval"]);
  };
  return (
    <Carousel
      onChange={onChange}
      autoPlay
      interval={intervalz}
      infiniteLoop={true}
    >
      {
        banners.map(banner => (
          <Link to="/products">
            <div data-interval={3000}>
              {/* <img style={{ img: 'cover' }} alt="" src={`${banner.images.url}`} /> */}
              <img style={{ img: 'cover' }} alt="" src={`https://lh3.googleusercontent.com/d/${banner.images.url.split("id=")[1]}=s1500?authuser=1`} referrerpolicy="no-referrer"/>
              {/* <h1 className="legend">{banner.title}</h1> */}
            </div>
          </Link>
        ))
      }
    </Carousel>
  )
}

export default Slider
