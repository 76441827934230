import React,{ useState, useContext, useEffect} from 'react'
import { GlobalState } from '../../../../GlobalState';
import axios from 'axios';


const Colors = ({colors, id, index, goToSlide}) => {
    const state = useContext(GlobalState);
    const [cart, setCart] = state.userAPI.cart;
    const [token] = state.token;
    const [color, setColor] = state.color;

    const addToCart = async () => {
        await axios.patch('/user/addcart', { cart }, {
            headers: { Authorization: token }
        })
    }


    const changeColor = (id, colorItem, index) => {
        setColor(colorItem);
        goToSlide(index - 1);
        // cart.forEach((item, i) => {
        //     if (item._id === id  && index == i) {
        //         item.color = colorItem;
        //     }
        // });

        // setCart([...cart]);
        // addToCart();
    }


        if(colors.length == 1) {
           colors =  colors[0].split(', ');
        }
       
    return (
        
       
    <div className="colors">
    <select name="color" id="size" value={color} onChange={(event) => changeColor(id, event.target.value, event.target.selectedIndex)} >
        <option value="0">Изберете цвят</option>
        {

            colors.map((colorItem, index) => (
                <option value={colorItem} key={index}>{colorItem}</option>
            ))

        }
    </select>
</div>
    )
}

export default Colors