import React, { useState, useContext, useEffect } from 'react'
import toastr from 'toastr';
import { GlobalState } from '../../../GlobalState';
import { Link } from 'react-router-dom';
import axios from 'axios';
import emailjs from 'emailjs-com';
import Colors from '../utils/Colors/Colors';
import Sizes from '../utils/Size/Size';
import toast, { Toaster } from 'react-hot-toast';
import Loading from '../utils/loading/Loading'
import Select from 'react-select';

const Cart = () => {
    const state = useContext(GlobalState);
    const [user] = state.userAPI.user;
    const [cart, setCart] = state.userAPI.cart;
    const [token] = state.token;
    const [total, setTotal] = useState(0)
    const [withoutRegistration] = state.withoutRegistration;
    const [choice, setChoice] = React.useState("1");
    const [options] = state.options1;
    const [loading, setLoading] = useState(false);
    const [selectedOption, setSelectedOption] = useState({});
    const [selectedOptionOffice, setSelectedOptionOffice] = useState({});
    const [offices, setOffices] = useState([]);
console.log(selectedOption)
    const handleChange = (selectedOption) => {
        setSelectedOptionOffice({})
        setSelectedOption(selectedOption);
        setOffices([]);
    };

    const onChangeAddress = (selectedOptionOffice) => {
        setSelectedOptionOffice(selectedOptionOffice)
        setUserOne({ ...userOne, ["address"]: "Еконт" + " - " + selectedOptionOffice.value, ["populated_place"]: "Еконт" + " - " + selectedOptionOffice.value });
    }

    const [userOne, setUserOne] = useState({
        name: '', email: '', password: '', telephone_number: '', populated_place: "", address: ""
    })

    const myArray = JSON.parse(window.localStorage.getItem('products'));

    useEffect(() => {
        if (!cart.length) {
            setCart(myArray);
        }
    }, []);


    let arr = [];

    useEffect(() => {
        const func = async () => {
            if (Object.keys(selectedOption).length >= 1) {
                setUserOne({ ...userOne, ["populated_place"]: "", ["address"]: "" });
                
                setLoading(true);
                const response = await axios.post(`https://ee.econt.com/services/Nomenclatures/NomenclaturesService.getOffices.json`, { countryCode: "BGR", cityID: selectedOption.value },
                { headers: { "Authorization": `Basic THVkbWlsX19ncmlnb3JvdkBhYnYuYmc6U2Ftb2Nza2E3Nzc=` } });
                if (response.status === 200) {
                    const arr = [];
                    response.data.offices.map((office) => (
                        arr.push({label: office.name + office.address.fullAddress, value: office.name + office.address.fullAddress})
                    ));
                    setOffices(arr);
                    setLoading(false);
                } else {
                    setLoading(false);
                }
            }
        };
        func();
    }, [selectedOption]);

    for (let i = 0; i < cart.length; i++) {
        if (cart[i].colors[0].length > 20) {
            arr.push(cart[i].colors[0].split(", ").indexOf(cart[i].color))
        } else {
            arr.push(cart[i].colors.indexOf(cart[i].color))

        }
    };

    const notify = () => toast.custom((t) => (
        <div className='containerModalTwo'>
            <div className='check'>&#10003; <span>Вашата поръчка е успешна! За повече детайли, моля посетете вашия email адрес!</span></div>

            <div className="containerButtons">
                <Link onClick={() => toast.dismiss(t.id)} to="/products">
                    <button className="buttonClose" onClick={() => toast.dismiss(t.id)}>
                        Продължете с пазаруването
                    </button>
                </Link>


                {/* <Link onClick={() => toast.dismiss(t.id)} to="/cart"><button className="buttonBuy">&#128722; Към количката</button></Link> */}
            </div>
        </div>
    ), { duration: 5000 });


    useEffect(() => {
        const getTotal = () => {
            const total = cart.reduce((prev, item) => {
                return prev + (item.price * item.quantity);
            }, 0);

            setTotal(total);
        }

        getTotal();
    }, [cart])

    const addToCart = async () => {
        await axios.patch('/user/addcart', { cart }, {
            headers: { Authorization: token }
        })
    }


    const increment = (id, index) => {
        cart.forEach((item, i) => {
            if (item._id === id && index == i) {
                item.quantity += 1;
            }
        });

        setCart([...cart]);
        window.localStorage.setItem('products', JSON.stringify([...cart]));
        if (!withoutRegistration) {
            addToCart();
        }
    }

    const decrement = (id, index) => {
        cart.forEach((item, i) => {
            if (item._id === id && index == i) {
                item.quantity === 1 ? item.quantity = 1 : item.quantity -= 1;
            }
        });

        setCart([...cart]);
        window.localStorage.setItem('products', JSON.stringify([...cart]));
        if (!withoutRegistration) {
            addToCart();
        }
    }

    const removeProdduct = (id, index) => {
        if (window.confirm("Сигурни ли сте, че искате да изтриете този продукт?")) {
            cart.forEach((item, i) => {
                if (item._id === id && index == i) {
                    cart.splice(index, 1);
                }
            });

            setCart([...cart]);
            window.localStorage.setItem('products', JSON.stringify([...cart]));
            if (!withoutRegistration) {
                addToCart();
            }
        }
    }

    const onChangeInput = e => {
        const { name, value } = e.target;
        setUserOne({ ...userOne, [name]: value })
    };

    

    function sendEmail(e) {
        e.preventDefault();

        for (const element of cart) {
            if (element.size_choice == 0 || element.hasOwnProperty('size_choice') == false) {
                return alert("Не сте избрали размер!")
            }

            if (element.color == 0 || element.hasOwnProperty('color') == false) {
                return alert("Не сте избрали цвят!")
            }
        }

        if (withoutRegistration) {

            if (userOne.name == '' && userOne.name.length < 5) {
                return alert("Полето Име и фамилия е задължително и трябва да съдържа най малко 5 знака!")
            }

            if (userOne.email == '') {
                return alert("Полето Email е задължително!")
            }

            if (userOne.telephone_number == '' && userOne.telephone_number.length < 10) {
                return alert("Полето Телефон е задължително и трябва да съдържа най - малко 10 цифри")
            }

            if (userOne.populated_place == "") {
                return alert("Полето населено място е задължително!")
            }

            if (userOne.address == "" && userOne.address < 10) {
                return alert("Полето Адрес за доставка е задължително и трябва да съдържа най - малко 10 символа!")
            }

        }

        emailjs.sendForm(
            'service_k46htce',
            'template_63evh4b',
            e.target,
            'user_3ke5d9TV80hRpR6NmI9dY'
        ).then(res => {
            console.log(res);
        }).catch(err => console.log(err));
        toastr.success('Вашата поръчка е изпратена');

        notify()

        axios.post('/api/email', withoutRegistration ? { cart, userOne, total } : { cart, user, total }, {
            headers: { Authorization: token }
        })

        cart.splice(0);
        setCart([...cart]);
        window.localStorage.setItem('products', JSON.stringify([...cart]));
        addToCart();

    }




    if (cart.length === 0) {
        return (<>
            <Toaster
                position="top-center"
                reverseOrder={false}
            />
            <h2 style={{ textAlign: "center", fontSize: "4rem" }}>Количката е празна</h2>
        </>)

    }

    return (
        <>
            <div>
                {
                    cart.map((product, index) => (
                        <div className="detail cart" key={product._id}>
                            {
                                arr[index] === 0 ? <img src={`https://lh3.googleusercontent.com/d/${product.images.url.split("id=")[1]}=s500?authuser=1`} alt="" referrerpolicy="no-referrer" /> :
                                    arr[index] === 1 ? <img src={`https://lh3.googleusercontent.com/d/${product.imagesTwo.url.split("id=")[1]}=s500?authuser=1`} alt="" referrerpolicy="no-referrer" /> :
                                        arr[index] === 2 ? <img src={`https://lh3.googleusercontent.com/d/${product.imagesThree.url.split("id=")[1]}=s500?authuser=1`} alt="" referrerpolicy="no-referrer" /> :
                                            arr[index] === 3 ? <img src={`https://lh3.googleusercontent.com/d/${product.imagesFour.url.split("id=")[1]}=s500?authuser=1`} alt="" referrerpolicy="no-referrer" /> :
                                                arr[index] === 4 ? <img src={`https://lh3.googleusercontent.com/d/${product.imagesFive.url.split("id=")[1]}=s500?authuser=1`} alt="" referrerpolicy="no-referrer" /> :
                                                    arr[index] === 5 ? <img src={`https://lh3.googleusercontent.com/d/${product.imagesSix.url.split("id=")[1]}=s500?authuser=1`} alt="" referrerpolicy="no-referrer" /> :
                                                        arr[index] === 6 ? <img src={`https://lh3.googleusercontent.com/d/${product.imagesSeven.url.split("id=")[1]}=s500?authuser=1`} alt="" referrerpolicy="no-referrer" /> :
                                                            arr[index] === 7 ? <img src={`https://lh3.googleusercontent.com/d/${product.imagesEight.url.split("id=")[1]}=s500?authuser=1`} alt="" referrerpolicy="no-referrer" /> :
                                                                arr[index] === 8 ? <img src={`https://lh3.googleusercontent.com/d/${product.imagesNine.url.split("id=")[1]}=s500?authuser=1`} alt="" referrerpolicy="no-referrer" /> :
                                                                    arr[index] === 9 ? <img src={`https://lh3.googleusercontent.com/d/${product.imagesTen.url.split("id=")[1]}=s500?authuser=1`} alt="" referrerpolicy="no-referrer" /> :
                                                                        arr[index] === 10 ? <img src={`https://lh3.googleusercontent.com/d/${product.imagesEleven.url.split("id=")[1]}=s500?authuser=1`} alt="" referrerpolicy="no-referrer" /> :
                                                                            arr[index] === 11 ? <img src={`https://lh3.googleusercontent.com/d/${product.imagesTwelve.url.split("id=")[1]}=s500?authuser=1`} alt="" referrerpolicy="no-referrer" /> : null

                            }

                            <div className="box-detail">
                                <h2>{product.title}</h2>

                                <h3>{Number(product.price * product.quantity).toFixed(2)} лв.</h3>
                                <p>{product.description}</p>
                                <p>Избран Цвят: {product.color}</p>
                                <p>Избран Размер: {product.size_choice}</p>
                                <div className="amount">
                                    <button onClick={() => decrement(product._id, index)}> - </button>
                                    <span>{product.quantity}</span>
                                    <button onClick={() => increment(product._id, index)}> + </button>
                                </div>

                                <div className="delete" onClick={() => removeProdduct(product._id, index)}>X</div>
                            </div>
                        </div>
                    ))
                }


                <div className="total">
                    <h3>Сума: {Number(total).toFixed(2)} Лв.</h3>
                    <Link to="#!">Payment</Link>
                </div>

                <div className='containerChoice'>
                    <div>
                        <input
                            type="checkbox"
                            checked={choice === "1"}
                            onChange={() => setChoice("1")}
                        />
                        До офис на Еконт
                    </div>

                    <div>
                        <input
                            type="checkbox"
                            checked={choice === "2"}
                            onChange={() => setChoice("2")}
                        />
                        До адрес
                    </div>

                </div>

                {
                    withoutRegistration && choice === "2" ?

                        <div className="login-page">
                            <form>

                                <h2>Данни за доставка</h2>
                                <div className="register_page">
                                    <label htmlFor="name">Име, Фамилия*</label>
                                    <input type="text" name="name" required id="name"
                                        placeholder="Иван Иванов" value={userOne.name} onChange={onChangeInput} />

                                    <label htmlFor="email">Email*</label>
                                    <input id="email" type="email" name="email" required
                                        placeholder="email@gmail.com" value={userOne.email} onChange={onChangeInput} />

                                    <label htmlFor="telephone_number">Телефон*</label>
                                    <input type="text" name="telephone_number" required id="telephone_number"
                                        placeholder="XXXXXXXXXX" value={userOne.telephone_number} onChange={onChangeInput} />

                                    <label htmlFor="populated_place">Населено място*</label>
                                    <input type="text" name="populated_place" required id="populated_place"
                                        placeholder="София" value={userOne.populated_place} onChange={onChangeInput} />

                                    <label htmlFor="address">Адрес за доставка*</label>
                                    <input type="text" name="address" required id="address"
                                        placeholder="Ваш адрес за доставка или офис на Еконт" value={userOne.address} onChange={onChangeInput} />


                                </div>
                            </form>
                        </div>

                        : ""
                }

                {
                    choice === "1" ?
                        <div className="login-page">
                            <div className="colors">
                            <label style={{fontWeight: 'bold'}} htmlFor="name">Изберете Град*</label>
                                <Select                                   
                                    value={selectedOption}
                                    onChange={handleChange}
                                    options={options}
                                    styles={{
                                        control: (baseStyles, state) => ({
                                          ...baseStyles,
                                          borderColor: state.isFocused ? 'grey' : '#03A5CE',
                                          marginTop: '5px'
                                        }),
                                      }}
                                />


                            </div>
                            {
                                offices.length >= 1 ?
                                    
                                    <div className="colors" style={{marginTop: '10px'}}>
                                        <label style={{fontWeight: 'bold'}} htmlFor="name">Офис на Еконт*</label>
                                        <Select
                                        styles={{
                                            control: (baseStyles, state) => ({
                                              ...baseStyles,
                                              borderColor: state.isFocused ? 'grey' : '#03A5CE',
                                              marginTop: '5px'
                                            }),
                                          }}
                                            placeholder="Изберете офис"
                                            value={selectedOptionOffice}
                                            onChange={onChangeAddress}
                                            options={offices}
                                        />

                                    </div>
                                    : ""
                            }

                            {
                                loading ? <Loading /> : ""
                            }
                            <form>

                                <h2>Данни за доставка</h2>
                                <div className="register_page">
                                    <label htmlFor="name">Име, Фамилия*</label>
                                    <input type="text" name="name" required id="name"
                                        placeholder="Иван Иванов" value={userOne.name} onChange={onChangeInput} />

                                    <label htmlFor="email">Email*</label>
                                    <input id="email" type="email" name="email" required
                                        placeholder="email@gmail.com" value={userOne.email} onChange={onChangeInput} />

                                    <label htmlFor="telephone_number">Телефон*</label>
                                    <input type="text" name="telephone_number" required id="telephone_number"
                                        placeholder="XXXXXXXXXX" value={userOne.telephone_number} onChange={onChangeInput} />

                                    <label htmlFor="populated_place">Населено място*</label>
                                    <input disabled={true} type="text" name="populated_place" required id="populated_place"
                                        placeholder="София" value={userOne.populated_place} onChange={onChangeInput} />

                                    <label htmlFor="address">Адрес за доставка*</label>
                                    <input disabled={true} type="text" name="address" required id="address"
                                        placeholder="Ваш адрес за доставка или офис на Еконт" value={userOne.address} onChange={onChangeInput} />


                                </div>
                            </form>
                        </div>

                        : ""
                }


                <form onSubmit={sendEmail}>

                    <input type="hidden" name="name" value={withoutRegistration ? userOne.name : user.name} />
                    <input type="hidden" name="user_email" value={withoutRegistration ? userOne.email : user.email} />
                    <input type="hidden" name="telephone_number" value={withoutRegistration ? userOne.telephone_number : user.telephone_number} />
                    <input type="hidden" name="populated_place" value={withoutRegistration ? userOne.populated_place : user.populated_place} />
                    <input type="hidden" name="address" value={withoutRegistration ? userOne.address : user.address} />
                    {
                        cart.map(product => (
                            <>
                                <input type="hidden" name="product_name" value={product.title} />
                                <input type="hidden" name="product_quantity" value={product.quantity} />
                                <input type="hidden" name="product_id" value={product.product_id} />
                                <input type="hidden" name="product_size" value={product.size_choice} />
                                <input type="hidden" name="product_color" value={product.color} />
                                <input type="hidden" name="product_price" value={Number(product.price).toFixed(2) + "лв."} />


                            </>

                        ))

                    }
                    <input type="hidden" name="product_price_all" value={Number(total).toFixed(2) + "лв."} />
                    <input className="input_buy" type="submit" value="Поръчай" />
                </form>
            </div>
        </>
    )
}

export default Cart
