import React, {useContext} from 'react';
import {GlobalState} from '../../../../GlobalState';
import { Link} from 'react-router-dom';
import styled from "styled-components";
import { mobile } from "../responsive";
import { Height } from '@material-ui/icons';

const Container = styled.div`
  flex: 1;
  margin: 3px;
  height: 70vh;
  position: relative;
  display: inline-block;
  ${mobile({height: "30vh"})}
  
`;

const Image = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
  ${mobile({ height: "30vh"})}
`;

const Info = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

const Title = styled.h1`
    color:black;
    background-color: white;
    margin-bottom: 20px;
    width: 100%;
    text-align: center;
    ${mobile({ fontSize: "10px"})}
`;

const Button = styled.button`
    border:none;
    padding: 10px;
    background-color: white;
    color:gray;
    cursor: pointer;
    font-weight: 600;
    ${mobile({ fontSize: "8px"})}
`;

const CategoryItem = ({item}) => {
  const state = useContext(GlobalState);
  const [category, setCategory] = state.productsAPI.category
  const words = item.img.split("id=")

    return (
        <Container>
            <Image src={`https://lh3.googleusercontent.com/d/${words[1]}=s500?authuser=1`} referrerpolicy="no-referrer" />
            <Info>
                <Title>{item.name}</Title>
                <Link to="/products"><Button onClick={() => setCategory("category=" + item._id)}>РАЗГЛЕДАЙ ТУК</Button></Link>
            </Info>
        </Container>
    )
}

export default CategoryItem;
