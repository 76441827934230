import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import axios from 'axios'

function Register() {
    const [user, setUser] = useState({
        name: '', email: '', password: '', telephone_number: '', populated_place: "", address: ""
    })

    const onChangeInput = e => {
        const { name, value } = e.target;
        setUser({ ...user, [name]: value })
    }

    const registerSubmit = async e => {
        e.preventDefault()
        try {
            await axios.post('/user/register', { ...user });

            localStorage.setItem('firstLogin', true);

            window.location.href = "/";
        } catch (err) {
            alert(err.response.data.msg)
        }
    }

    return (
        <div className="login-page">
            <form onSubmit={registerSubmit}>
                
                <h2>Регистрация</h2>
                <div className="register_page">
                <label htmlFor="name">Име, Фамилия*</label>
                <input type="text" name="name" required id="name"
                    placeholder="Иван Иванов" value={user.name} onChange={onChangeInput} />
                
                <label htmlFor="email">Email*</label>
                <input id="email" type="email" name="email" required
                    placeholder="email@gmail.com" value={user.email} onChange={onChangeInput} />
        
                <label htmlFor="telephone_number">Телефон*</label>
                <input type="text" name="telephone_number" required id="telephone_number"
                    placeholder="XXXXXXXXXX" value={user.telephone_number} onChange={onChangeInput} />

                <label htmlFor="populated_place">Населено място*</label>
                <input type="text" name="populated_place" required id="populated_place"
                    placeholder="София" value={user.populated_place} onChange={onChangeInput} />
                
                <label htmlFor="address">Адрес за доставка*</label>
                <input type="text" name="address" required id="address"
                    placeholder="Ваш адрес за доставка или офис на Еконт" value={user.address} onChange={onChangeInput} />
                
                <label htmlFor="password">Парола*</label>
                <input type="password" name="password" required id="password" autoComplete="on"
                    placeholder="Парола" value={user.password} onChange={onChangeInput} />

                <div className="row">
                    <button type="submit">Регистрация</button>
                    <Link to="/login">Влез</Link>
                </div>
                </div>
            </form>
        </div>
    )
}

export default Register